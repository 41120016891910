import React, { useRef, useState } from "react";
import { Link } from 'react-router-dom';
import '../CSS/illo.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/illo/illustrations_intro_logo.png';
import Illo1 from '../assets/illo/illo1.jpg';
import Illo2 from '../assets/illo/illo2.jpg';
import Illo3 from '../assets/illo/illo3.jpg';
import Illo4 from '../assets/illo/illo4.jpg';
import Illo5 from '../assets/illo/illo5.png';
import Illo6 from '../assets/illo/illo6.jpg';
import Illo7 from '../assets/illo/illo7.jpg';
import Illo8 from '../assets/illo/illo8.jpg';
import Illo9 from '../assets/illo/illo9.jpg';


const Illo = () => {
    return (
        <div className="container illo-container project-container">
            <div className="header-container">
                <img src={Logo} alt="Illustrations" />
            </div>

            <div className="panel1">
                <div className="centered-container">
                    <h2>Illustrations</h2> 
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Illo1} alt="Illustrations" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Illo2} alt="Illustrations" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Illo3} alt="Illustrations" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Illo4} alt="Illustrations" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Illo5} alt="Illustrations" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Illo6} alt="Illustrations" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Illo7} alt="Illustrations" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Illo8} alt="Illustrations" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Illo9} alt="Illustrations" />
                                </div>
                            </div>      
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

        </div> /* illo-container */
    );
};

export default Illo;