import React, { useRef, useState } from "react";
import '../CSS/bft.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/bft/b4t_intro_logo.png';
import Bft1 from '../assets/bft/b4t1.jpg';
import Bft2 from '../assets/bft/b4t2.jpg';
import Bft3 from '../assets/bft/b4t3.jpg';
import Bft4 from '../assets/bft/b4t4.jpg';
import Bft5 from '../assets/bft/b4t5.jpg';
import Bft6 from '../assets/bft/b4t6.jpg';
import Bft7 from '../assets/bft/b4t7.jpg';
import Bft8 from '../assets/bft/b4t8.jpg';
import Bft9 from '../assets/bft/b4t9.jpg';
import Bft10 from '../assets/bft/b4t10.jpg';
import Bft11 from '../assets/bft/b4t11.jpg';
import Bft12 from '../assets/bft/b4t12.jpg';
import Bft13 from '../assets/bft/b4t13.jpg';
import Bft14 from '../assets/bft/b4t14.jpg';
import Bft15 from '../assets/bft/b4t15.jpg';
import Bft16 from '../assets/bft/b4t16.jpg';
import Bft17 from '../assets/bft/b4t17.jpg';
import Bft18 from '../assets/bft/b4t18.jpg';


const Bft = () => {
    return (
        <div className="container bft-container project-container">
            <div className="header-container">
                <img src={Logo} alt="Battle For Treasure Logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>Battle for Treasure</h2>
                        <h3>Print</h3>
                    </div>
                    <div className="half-div">
                        <p>Battle for Treasure is a famiy fun playing card game where you collect treasure and battle foes. The client request for the design and illustration work was to be friendly, striking, and colorful. For this project, I crafted the illustrations, designs of the cards and the logo. </p>
                        <img src={Bft1} alt="Playing cards" />
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <div className="flex-container logo-container">
                        <div className="half-div">
                            <h3>Brand Identity</h3>
                            <h2>Logo</h2>
                            <img src={Logo} alt="Logo" />
                        </div>
                        <div className="half-div">
                            <p>When designing the logo, I wanted to make sure that it was bold, friendly, and captivating. It was important that it could stand out amongst other card games and have enough contrast and multiple background colors and graphics. </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <div className="flex-container ">
                        <div className="half-div">
                            <h3>Print</h3>
                            <h2>Treasure Cards</h2>
                        </div>
                        <div className="half-div">
                            <img src={Bft2} alt="Playing cards" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="panel4">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Defense Cards</h2>
                    <div className="flex-container ">
                        <div className="half-div">
                            <img src={Bft3} alt="Playing cards" />
                        </div>
                        <div className="half-div">
                            <img src={Bft4} alt="Playing cards" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel5">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Item Cards</h2>
                    <div className="flex-container ">
                        <div className="half-div">
                            <img src={Bft7} alt="Playing cards" />
                        </div>
                        <div className="half-div">
                            <img src={Bft12} alt="Playing cards" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel6">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Melee Cards</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bft6} alt="playing cards" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bft16} alt="playing cards" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bft10} alt="playing cards" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bft11} alt="playing cards" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bft3} alt="playing cards" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bft5} alt="playing cards" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bft4} alt="playing cards" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel6">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Spell Cards</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bft17} alt="playing cards" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bft8} alt="playing cards" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bft9} alt="playing cards" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bft18} alt="playing cards" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bft15} alt="playing cards" />
                                </div>
                            </div>      
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

        </div> /* Bft-container */
    );
};

export default Bft;