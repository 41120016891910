import React from "react";
import '../CSS/buna.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/buna/buna_intro_logo.png';
import Colors from '../assets/buna/colors.png';
import Whitewood from '../assets/buna/font-whitewood.png';
import Alegreya from '../assets/buna/font-alegreya.png';
import Buna1 from '../assets/buna/buna_1.jpg';
import Buna2 from '../assets/buna/buna_2.jpg';
import Buna3 from '../assets/buna/buna_3.jpg';
import Buna4 from '../assets/buna/buna_4.jpg';
import Buna5 from '../assets/buna/buna_5.jpg';
import Buna6 from '../assets/buna/buna_6.jpg';


const Buna = () => {
    return (
        <div className="container buna-container project-container">
            <div className="header-container">
                <img src={Logo} alt="buna logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container">
                    <div className="flex-container">
                        <div className="half-div">
                            <h2>Buna Tetu</h2>
                            <h3>Logo, UI/UX, Web Design</h3>
                        </div>
                        <div className="half-div">
                            <h3> History of Kaldi</h3>
                            <p>A story that explains the use of the goat as the logo icon</p>
                            <p>The origin of coffee is surrounded in myth. Legend has it that one day long ago, an Ethiopiagoatherd named Kaldi found his goats grazing on a bush of bright red berries. When his goats ate the berries, they began to dance and jump with energy - incredible! <br/><br/>

                            From the excitement of his discovery, Kaldi decided to bring the berries to a monk, however he disapproved and threw them into a fire. The aroma from the beans was so enticing that the monk quickly gathered the roasted beans, grounded them, and dissolved them in the hot water, creating the first cup of coffee.<br/><br/> </p>

                            <h3>Buna Tetu Meaning</h3>
                            <p>"DRINK COFFEE"</p>
                            <p>A phrase of respect and friendship that runs deep within the Ethiopian culture
                            The history of Buna Tetu lends itself to the traditional coffee ceremony in Ethiopia. To be invited to attend a coffee ceremony is considered respectful or a sign of friendship and is obligatory in any presence of a visitor. With the coffee being an integral part of social and cultural life, Buna Tetu is meant to recreate a rich coffee that embodies the flavors and aromas from the traditional ceremony.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <h3>Brand Identity</h3>
                    <h2>Typeface & Colors</h2>
                    <div className="flex-container brand-identity">
                        <div className="half-div color-container">
                            <h3>Color Scheme</h3>
                            <img src={Colors} alt=" Colors" />
                        </div>
                        <div className="half-div">
                            <h3>Fonts</h3>
                            <div className="flex-container font-container">
                                <div className="half-div">
                                    <img src={Whitewood} alt=" White Wood Font" />
                                    <p>White Wood</p>
                                </div>
                                <div className="half-div">
                                    <img src={Alegreya} alt=" Alegreya" />
                                    <p>Alegreya Sans</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <div className="flex-container logo-container">
                        <div className="half-div">
                            <h3>Brand Identity</h3>
                            <h2>Logo</h2>
                            <img src={Logo} alt="buna Logo" />
                        </div>
                        <div className="half-div">
                            <p>When designing the logo, I wanted to make sure that it had character, felt friendly but maintained professionalism.  </p>
                            <div className="logo-prototypes">
                                <h3>Sketches</h3>
                                <div className="flex-container">
                                    <div className="full-div">  
                                        <img src={Buna6} alt="Buna Logo Sketches" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel4">
                <div className="centered-container">
                    <h3>UI/UX, Web Design, Web Development</h3>
                    <h2>Website</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Buna2} alt="Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Buna3} alt="Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Buna4} alt="Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel5">
                <div className="centered-container">
                    <h3>Product Design</h3>
                    <h2>Coffee Label</h2>
                    <div className="flex-container">
                        <div className="full-div">
                            <img src={Buna5} alt="Label" />
                        </div>
                    </div>
                </div>
            </div>


        
        </div> /* buna-container */
    );
};

export default Buna;