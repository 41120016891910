import React, { useRef, useState } from "react";
import '../CSS/lizTeam.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/liz-team/lizteam_intro_logo.png';
import Lt1 from '../assets/liz-team/lizteam1.jpg';
import Lt2 from '../assets/liz-team/lizteam2.jpg';
import Lt3 from '../assets/liz-team/lizteam3.jpg';
import Lt4 from '../assets/liz-team/lizteam4.jpg';
import Lt5 from '../assets/liz-team/lizteam5.jpg';
import Lt6 from '../assets/liz-team/lizteam6.jpg';
import Lt7 from '../assets/liz-team/lizteam7.jpg';

import Bp1 from '../assets/liz-team/buyer-presentation.jpg';
import Bp2 from '../assets/liz-team/buyer-presentation2.jpg';
import Bp3 from '../assets/liz-team/buyer-presentation3.jpg';
import Bp4 from '../assets/liz-team/buyer-presentation4.jpg';
import Bp5 from '../assets/liz-team/buyer-presentation5.jpg';
import Bp6 from '../assets/liz-team/buyer-presentation6.jpg';
import Bp7 from '../assets/liz-team/buyer-presentation7.jpg';
import Bp8 from '../assets/liz-team/buyer-presentation8.jpg';
import Bp9 from '../assets/liz-team/buyer-presentation9.jpg';
import Bp10 from '../assets/liz-team/buyer-presentation10.jpg';
import Bp11 from '../assets/liz-team/buyer-presentation11.jpg';
import Bp12 from '../assets/liz-team/buyer-presentation12.jpg';
import Bp13 from '../assets/liz-team/buyer-presentation13.jpg';
import Bp14 from '../assets/liz-team/buyer-presentation14.jpg';
import Bp15 from '../assets/liz-team/buyer-presentation15.jpg';
import Bp16 from '../assets/liz-team/buyer-presentation16.jpg';

import Newsletter1 from '../assets/liz-team/newsletter0.jpg';
import Newsletter2 from '../assets/liz-team/newsletter1.jpg';
import Newsletter3 from '../assets/liz-team/newsletter2.jpg';
import Newsletter4 from '../assets/liz-team/newsletter3.jpg';
import Newsletter5 from '../assets/liz-team/newsletter4.jpg';


const LizTeam = () => {
    return (
        <div className="container lt-container project-container">
            <div className="header-container">
                <img src={Logo} alt="The Liz Team Logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>The Liz Team</h2>
                        <h3>Print, Product Design</h3>
                    </div>
                    <div className="half-div">
                        <p>The Liz Team is a real estate agency located in Orlando, Florida. I was the lead designer in creating their promotional materials, both web and print. </p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <div className="flex-container ">
                        <div className="half-div">
                            <h3>Product Design</h3>
                            <h2>For Sale Sign</h2>
                        </div>
                        <div className="half-div">
                            <img src={Lt1} alt="For Sale Sign" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="panel3">
                <div className="centered-container">
                    <div className="flex-container ">
                        <div className="half-div">
                            <h3>Web Design</h3>
                            <h2>Email Signature</h2>
                        </div>
                        <div className="half-div">
                            <img src={Lt2} alt="Email Signature" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="panel4">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Flyer</h2>
                    <div className="flex-container ">
                        <div className="half-div">
                            <img src={Lt6} alt="Flyer" />
                        </div>
                        <div className="half-div">
                            <img src={Lt7} alt="Flyer" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel5">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Buyer Presentation</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp1} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp2} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp3} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp4} alt="Buyer Presentation" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp5} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp6} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp7} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp8} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp9} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp10} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp11} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp12} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp13} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp14} alt="Buyer Presentation" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp15} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Bp16} alt="Buyer Presentation" />
                                </div>
                            </div>      
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel6">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Newsletter</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Newsletter1} alt="Newsletter" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Newsletter2} alt="Newsletter" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Newsletter3} alt="Newsletter" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Newsletter4} alt="Newsletter" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Newsletter5} alt="Newsletter" />
                                </div>
                            </div>      
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel7">
                <div className="centered-container">
                    <h3>Product Design</h3>
                    <h2>Tent & Banner</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Lt5} alt="Tent and Banner" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Lt3} alt="Tent" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Lt4} alt="Banner" />
                                </div>
                            </div>      
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

           

        </div> /* LizTeam-container */
    );
};

export default LizTeam;