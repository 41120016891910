import React from "react";
import '../CSS/sushi-katana.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/sushi-katana/sk_intro_logo.png';
import Logo2 from '../assets/sushi-katana/sk_intro_logo-2.png';
import Sk1 from '../assets/sushi-katana/Sk1.jpg';
import Sk2 from '../assets/sushi-katana/Sk2.jpg';
import Sk3 from '../assets/sushi-katana/Sk3.jpg';
import Sk4 from '../assets/sushi-katana/Sk4.jpg';
import Sk5 from '../assets/sushi-katana/Sk5.jpg';
import Sk6 from '../assets/sushi-katana/Sk6.jpg';
import Sk7 from '../assets/sushi-katana/Sk7.jpg';
import Addy from '../assets/addy.png';
import Menu from '../assets/../assets/sushi-katana/menuMock.jpg';
import Spread1 from '../assets/../assets/sushi-katana/spread1.jpg';
import Spread2 from '../assets/../assets/sushi-katana/spread2.jpg';
import Spread3 from '../assets/../assets/sushi-katana/spread3.jpg';
import Spread4 from '../assets/../assets/sushi-katana/spread4.jpg';
import Spread5 from '../assets/../assets/sushi-katana/spread5.jpg';



const SushiKatana = () => {
    return (
        <div className="container sk-container project-container">
            <div className="header-container">
                <img src={Logo} alt="Sushi Katana logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container">
                    <div className="flex-container">
                        <div className="half-div">
                            <h2>Sushi Katana</h2>
                            <h3>Logo, Print</h3>
                        </div>
                        <div className="half-div">
                            <p>Sushi Katana is a restaurant located in Orlando, FL. I thought that their name could lend itself to a more interesting logo than what they currently have so I decided to rebrand it as a passion project. </p>
                            <img src={Addy} alt="sushi katana addy" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <div className="flex-container logo-container">
                        <div className="half-div">
                            <h3>Brand Identity</h3>
                            <h2>Logo</h2>
                            <img src={Logo2} alt="sushi katana Logo" />
                        </div>
                        <div className="half-div">
                            <p>The logo consists of chopsticks holding sushi but instead of chopsticks, they are katanas.  </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Letterhead & Business Cards</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Sk1} alt="Letterhead and Business Cards" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Sk2} alt="Business Cards" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Sk3} alt="Letterhead" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Sk4} alt="Envelope" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel4">
                <div className="centered-container">
                    <h3>Product Design</h3>
                    <h2>Chopsticks & To-Go Box</h2>
                    <div className="flex-container">
                        <div className="half-div">
                            <img src={Sk5} alt="Chopsticks" />
                        </div>
                        <div className="half-div">
                            <img src={Sk6} alt="To-go Box" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel5">
                <div className="centered-container">
                    <h3>Apparel Design</h3>
                    <h2>Shirt</h2>
                    <div className="flex-container">
                        <div className="full-div">
                            <img src={Sk7} alt="Shirt" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel6">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Menu</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Menu} alt="Menu and Business Cards" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Spread1} alt="Menu" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Spread2} alt="Menu" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Spread3} alt="Menu" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Spread4} alt="Menu" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Spread5} alt="Menu" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>


        
        </div> /* SushiKatana-container */
    );
};

export default SushiKatana;