import React from "react";
import '../CSS/wanderian.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/wanderian/wanderian_intro_logo.png';
import Wanderian1 from '../assets/wanderian/wanderian1.jpg';
import Wanderian2 from '../assets/wanderian/wanderian2.jpg';
import Wanderian3 from '../assets/wanderian/wanderian3.jpg';
import Wanderian4 from '../assets/wanderian/wanderian4.jpg';
import Wanderian5 from '../assets/wanderian/wanderian5.jpg';
import Wanderian6 from '../assets/wanderian/wanderian6.jpg';
import Wanderian7 from '../assets/wanderian/wanderian7.jpg';
import Wanderian8 from '../assets/wanderian/wanderian8.jpg';
import Wanderian9 from '../assets/wanderian/wanderian9.jpg';
import Wanderian10 from '../assets/wanderian/wanderian10.jpg';
import Wanderian11 from '../assets/wanderian/wanderian11.jpg';
import Wanderian12 from '../assets/wanderian/wanderian12.jpg';
import Wanderian13 from '../assets/wanderian/wanderian13.jpg';
import Wanderian14 from '../assets/wanderian/wanderian14.jpg';
import Wanderian15 from '../assets/wanderian/wanderian15.jpg';
import Store1 from '../assets/wanderian/store1.jpg';
import Store2 from '../assets/wanderian/store2.jpg';
import Store3 from '../assets/wanderian/store3.jpg';
import Store4 from '../assets/wanderian/store4.jpg';

const Wanderian = () => {
    return (
        <div className="container wanderian-container project-container">
            <div className="header-container">
                <img src={Logo} alt="Wanderian logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container">
                    <div className="flex-container">
                        <div className="half-div">
                            <h2>Wanderian</h2>
                            <h3>Logo, UI/UX, Web Design</h3>
                        </div>
                        <div className="half-div">
                            <p>Something that I noticed as I traveled with others was that planning on where we all wanted to go was inefficient and troublesome. I researched for something that can make it easier but only found apps that are for solo travel planning. Therefore, I wanted to create something for collaborating on a travel itinerary as a group. </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <div className="flex-container logo-container">
                        <div className="half-div">
                            <h3>Brand Identity</h3>
                            <h2>Logo</h2>
                            <img src={Logo} alt="Wanderian Logo" />
                        </div>
                        <div className="half-div">
                            <p>To represent a wanderer, I chose to have the form of a person walking in the middle of the "W". This lends itself great for animations of the icon especially for the app loading screen. </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <h3>Web Design</h3>
                    <h2>Store Screens</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Store1} alt="Store Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={Store2} alt="Store Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Store3} alt="Store Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={Store4} alt="Store Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel4">
                <div className="centered-container">
                    <h3>UI/UX</h3>
                    <h2>Mobile Screens</h2>
                    <a href="https://www.figma.com/file/LjrOAzJQ2dZCwwnXUJAfmY/Wanderian?node-id=2%3A5" target="_blank" rel="noreferrer"><button>View Prototype</button></a>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Wanderian1} alt="Wanderian Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={Wanderian2} alt="Wanderian Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Wanderian3} alt="Wanderian Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={Wanderian4} alt="Wanderian Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Wanderian5} alt="Wanderian Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={Wanderian6} alt="Wanderian Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Wanderian7} alt="Wanderian Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={Wanderian8} alt="Wanderian Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Wanderian9} alt="Wanderian Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={Wanderian10} alt="Wanderian Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Wanderian11} alt="Wanderian Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={Wanderian12} alt="Wanderian Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Wanderian13} alt="Wanderian Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={Wanderian14} alt="Wanderian Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Wanderian15} alt="Wanderian Screens" />
                                </div>
                                <div className="half-div">  
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>


        
        </div> /* wanderian-container */
    );
};

export default Wanderian;