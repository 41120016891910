import React from "react";
import '../CSS/metalocator.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/metalocator/metalocator-intro-logo.png';
import Alliance1 from '../assets/metalocator/alliance-design-1.jpg';
import Alliance2 from '../assets/metalocator/alliance-design-2.jpg';
import Alliance3 from '../assets/metalocator/alliance-design-3.jpg';
import Alliance4 from '../assets/metalocator/alliance-design-4.jpg';
import Alliance5 from '../assets/metalocator/alliance-design-5.jpg';
import Alliance6 from '../assets/metalocator/alliance-design-6.jpg';
import Flowserve1 from '../assets/metalocator/flowserve-1.jpg';
import Flowserve2 from '../assets/metalocator/flowserve-2.jpg';
import Haworth from '../assets/metalocator/haworth-1.jpg';


const Metalocator = () => {
    return (
        <div className="container metalocator-container project-container">
            <div className="header-container">
                <img src={Logo} alt="Metalocator logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>Metalocator</h2>
                        <h3>SEO Pages, UI/UX, Web Design</h3>
                    </div>
                    <div className="half-div">
                        <p> Metalocator is a feature-rich and highly-configurable locator software. Complete with many features such as search by zip code, address or auto-detect, mapping, automatic geolocation and more. Their many clients around the world benefit from their CMS systems as well as SEO location pages.</p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h3>Client guidelines</h3>
                        <h2>Design</h2>
                    </div>
                    <div className="half-div">
                        <p> As I create the web pages for various clients, I have to ensure to maintain their company brand guidelines. Assets such as their logo usage, brand colors, typefaces, and more are important to keep their brand strong and maintain consistency with their current website.</p>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <h3>UI/UX, Web Design, & Development</h3>
                    <h2>Alliance Laundry</h2>

                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Alliance1} alt="Alliance Laundry" />
                                </div>
                                <div className="half-div">  
                                    <img src={Alliance2} alt="Alliance Laundry" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                      
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Alliance3} alt="Alliance Laundry" />
                                </div>
                                <div className="half-div">  
                                    <img src={Alliance4} alt="Alliance Laundry" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                      
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Alliance5} alt="Alliance Laundry" />
                                </div>
                                <div className="half-div">  
                                    <img src={Alliance6} alt="Alliance Laundry" />
                                </div>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </div>

            <div className="panel4">
                <div className="centered-container">
                    <h3>UI/UX, Web Design, & Development</h3>
                    <h2>Flowserve</h2>
                    <div className="flex-container logo-prototypes">
                        <div className="half-div">  
                            <img src={Flowserve1} alt="Flowserve" />
                        </div>
                        <div className="half-div">  
                            <img src={Flowserve2} alt="Flowserve" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel5">
                <div className="centered-container">
                    <h3>UI/UX, Web Design, & Development</h3>
                    <h2>Haworth</h2>
                    <div className="flex-container logo-prototypes">
                        <div className="half-div">  
                            <img src={Haworth} alt="Haworth" />
                        </div>
                        <div className="half-div">  
                        </div>
                    </div>
                </div>
            </div>

        </div> /* Metalocator-container */
    );
};

export default Metalocator;