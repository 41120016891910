import React from "react";


const Footer = () => {

    return (
        <div className="footer">
            <div className="centered-container">
                <p>&copy; Copyright Joy Garcia 2022. All Rights Reserved</p> 
                <p>|</p>   
                <a href="mailto:design@joygarcia.com">design@joygarcia.com</a>
            </div>
        </div>
    );

};

export default Footer;