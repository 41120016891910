import React, { useRef, useState } from "react";
import { Link } from 'react-router-dom';
import '../CSS/leslie.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/leslie-hielema/leslie_intro_logo.png';
import Logo2 from '../assets/leslie-hielema/leslie_intro_logo2.png';
import Leslie1 from '../assets/leslie-hielema/site1.png';
import Leslie2 from '../assets/leslie-hielema/site2.png';
import Leslie3 from '../assets/leslie-hielema/site3.png';
import Leslie4 from '../assets/leslie-hielema/site4.png';
import Leslie5 from '../assets/leslie-hielema/site5.png';
import Leslie6 from '../assets/leslie-hielema/site6.png';


const Tkci = () => {
    return (
        <div className="container leslie-container project-container">
            <div className="header-container">
                <img src={Logo} alt="Leslie Hielema logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>Leslie Hielema</h2>
                        <h3>Logo, UI/UX, Web Design, Web Development</h3>
                    </div>
                    <div className="half-div">
                        <p> leslie Hielema required a redesign of her website. I had to reorganize her site, create new elements, and craft a new logo. </p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <div className="flex-container logo-container">
                        <div className="half-div">
                            <h3>Brand Identity</h3>
                            <h2>Logo</h2>
                            <img src={Logo2} alt="Leslie Logo" />
                        </div>
                        <div className="half-div">
                            <p>Leslie requested her logo to have a script element to it. I made sure to highlight her first name in the logo for recognition but also contrast to the rest of the logo.  </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <h3>UI/UX, Web Design, Web Development</h3>
                    <h2>Website</h2> 
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Leslie1} alt="Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Leslie2} alt="Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Leslie3} alt="Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Leslie4} alt="Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Leslie5} alt="Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Leslie6} alt="Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>



                </div>
            </div>


        </div> /* tkci-container */
    );
};

export default Tkci;