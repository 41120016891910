import React, {useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import ScrollToTop from "./ScrollToTop";

import Home from '../components/Home';
import Atls from '../components/Atls';
import Bft from '../components/Bft';
import BunaTetu from '../components/Buna';
import Disti from '../components/Disti';
import Fcb from '../components/Fcb';
import Gmtm from '../components/Gmtm';
import Ito from '../components/Ito';
import Illo from '../components/Illo';
import Leslie from '../components/Leslie';
import LizTeam from '../components/LizTeam';
import MemoryMates from '../components/MemoryMates';
import Metalocator from '../components/Metalocator';
import Nhfk from '../components/Nhfk';
import Photoday from '../components/Photoday';
import Reedy from '../components/Reedy';
import Suff from '../components/Suff';
import SushiKatana from '../components/SushiKatana';
import Teachboost from '../components/Teachboost';
import Tkci from '../components/Tkci';
import Ucf from '../components/Ucf';
import Wanderian from '../components/Wanderian';

import ReactGA from 'react-ga';

const TRACKING_ID = "UA-54526922-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


function Pages() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);
      
    return(
        <Router>
            <ScrollToTop />
            <Nav />
            <Routes>
                <Route exact path="/" element={<Home/>}/> 
                <Route exact path="/atls" element={<Atls/>}/>
                <Route exact path="/battle-for-treasure" element={<Bft/>}/> 
                <Route exact path="/buna-tetu" element={<BunaTetu/>}/>   
                <Route exact path="/disti" element={<Disti/>}/> 
                <Route exact path="/fcb" element={<Fcb/>}/> 
                <Route exact path="/gmtm" element={<Gmtm/>}/>  
                <Route exact path="/ito" element={<Ito/>}/>
                <Route exact path="/illustrations" element={<Illo/>}/>
                <Route exact path="/leslie" element={<Leslie/>}/>
                <Route exact path="/memory-mates" element={<MemoryMates/>}/> 
                <Route exact path="/metalocator" element={<Metalocator/>}/> 
                <Route exact path="/new-hope-for-kids" element={<Nhfk/>}/> 
                <Route exact path="/photoday" element={<Photoday/>}/>   
                <Route exact path="/reedy-photo" element={<Reedy/>}/>   
                <Route exact path="/stand-up-for-forests" element={<Suff/>}/> 
                <Route exact path="/sushi-katana" element={<SushiKatana/>}/>  
                <Route exact path="/teachboost" element={<Teachboost/>}/>
                <Route exact path="/the-liz-team" element={<LizTeam/>}/>  
                <Route exact path="/tkci" element={<Tkci/>}/>  
                <Route exact path="/ucf" element={<Ucf/>}/>  
                <Route exact path="/wanderian" element={<Wanderian/>}/>               
            </Routes>
            <Footer />
        </Router>
    );  
};

export default Pages;



