import React, { useRef, useState } from "react";
import { Link } from 'react-router-dom';
import '../CSS/atls.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/atls/atls_intro_logo2.png';
import Atls1 from '../assets/atls/atls_1.jpg';
import Atls2 from '../assets/atls/atls_3.jpg';
import Atls3 from '../assets/atls/atls_4.jpg';
import Atls4 from '../assets/atls/atls_5.jpg';
import Atls5 from '../assets/atls/atls_6.jpg';
import Atls6 from '../assets/atls/atls_7.jpg';


const Atls = () => {
    return (
        <div className="container atls-container project-container">
            <div className="header-container">
                <img src={Logo} alt="ATLS logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>ATLS</h2>
                        <h3>Print, Textbook</h3>
                    </div>
                    <div className="half-div">
                        <p> ATLS is a program developed by the American College of Surgeons (ACS) Committee on Trauma (COT) to teach students a systematic, concise approach to the care of a trauma patient. Its courses provide safe and reliable methods for immediate management of injured patients. I redesigned and created the layout for the tenth edition student and faculty books. </p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <h3>Design, Layout</h3>
                    <h2>Textbook</h2> 
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Atls1} alt="ATLS Textbook" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Atls2} alt="ATLS Textbook" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Atls3} alt="ATLS Textbook" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Atls4} alt="ATLS Textbook" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Atls5} alt="ATLS Textbook" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Atls6} alt="ATLS Textbook" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

        </div> /* atls-container */
    );
};

export default Atls;