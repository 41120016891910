import React, { useRef, useState } from "react";
import '../CSS/memory-mates.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/memory-mates/memory_mates_intro_logo.png';
import Mm1 from '../assets/memory-mates/memory_mates1.jpg';
import Mm2 from '../assets/memory-mates/memory_mates2.jpg';
import Mm3 from '../assets/memory-mates/memory_mates3.jpg';
import Mm4 from '../assets/memory-mates/memory_mates4.jpg';
import Mm5 from '../assets/memory-mates/memory_mates5.jpg';
import Mm6 from '../assets/memory-mates/memory_mates6.jpg';
import Mm7 from '../assets/memory-mates/memory_mates7.jpg';
import Mm8 from '../assets/memory-mates/memory_mates8.jpg';
import Mm9 from '../assets/memory-mates/memory_mates9.jpg';


const MemoryMates = () => {
    return (
        <div className="container memory-mates-container project-container">
            <div className="header-container">
                <img src={Logo} alt="Memory Mates" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>Memory Mates</h2>
                        <h3>Print</h3>
                    </div>
                    <div className="half-div">
                        <p> Memory Mates are designed templates that have designated spaces for an individual photo as well as a team or group photo. It is typically available for a wide variety of activities and are used to display a child in a sport or school. </p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Mm1} alt="memory mates" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Mm5} alt="memory mates" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Mm6} alt="memory mates" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Mm7} alt="memory mates" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

        </div> /* MemoryMates-container */
    );
};

export default MemoryMates;