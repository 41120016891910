import React, { useRef, useState } from "react";
import '../CSS/reedy.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/reedy/reedy_intro_logo.png';
import OldLogo from '../assets/reedy/reedy-old-logo.png';
import Reedy1 from '../assets/reedy/reedy_1.jpg';
import Reedy2 from '../assets/reedy/reedy_2.jpg';
import Reedy3 from '../assets/reedy/reedy_3.jpg';
import Reedy4 from '../assets/reedy/reedy_4.jpg';
import Reedy5 from '../assets/reedy/reedy_5.png';
import Reedy6 from '../assets/reedy/reedy_6.png';
import Reedy7 from '../assets/reedy/reedy_7.png';


const Reedy = () => {
    return (
        <div className="container reedy-container project-container">
            <div className="header-container">
                <img src={Logo} alt="Reedy Logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>Reedy Photo</h2>
                        <h3>Logo, Print, UI/UX, Web Design</h3>
                        <a href="https://reedyphoto.com/" target="_blank" rel="external nofollow"><button>View Website</button></a>
                    </div>
                    <div className="half-div">
                        <p> Reedy Photo is a print lab created in 1967 and currently located in St. Petersburg, FL. They originally began as a lab for film and eventually transitioned to digital. They are still serving professional photographers today, offering a variety of products for any type of photographer. </p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <div className="flex-container">
                        <div className="half-div">
                            <h3>Brand Identity</h3>
                            <h2>Logo</h2>
                        </div>
                        <div className="half-div">
                            <p>Reedy Photo was looking to modernize their logo while adding some character to it to make it unique. The goal was to have the logo stand out at various sizes and work well with social media profile constraints. The icon itself can stand alone while still being a unique representation of the company brand.   </p>
                        </div>
                    </div>

                    <div className="flex-container ">
                        <div className="half-div"> 
                            <img src={Logo} alt="Reedy Logo"className="new-logo" />
                            <p> New Logo</p>
                        </div>
                        <div className="half-div">
                            <img src={OldLogo} alt="Reedy Logo" className="old-logo"/>
                            <p> Old Logo</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Product Catalog</h2>

                    <div className="flex-container ">
                        <div className="half-div"> 
                            <img src={Reedy3} alt="Product catalog" />
                        </div>
                        <div className="half-div">
                            <img src={Reedy4} alt="Product catalog" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel4">
                <div className="centered-container">
                    <h3>UI/UX, Web Design, Web Development</h3>
                    <h2>Website</h2> 
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Reedy1} alt="Reedy Website" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Reedy5} alt="Reedy Website" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Reedy6} alt="Reedy Website" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Reedy7} alt="Reedy Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel5">
                <div className="centered-container">
                    <div className="flex-container">
                        <div className="half-div">
                            <h3>Print</h3>
                            <h2>Business Cards</h2>
                        </div>
                        <div className="half-div">
                            <img src={Reedy2} alt="Business Cards" />
                        </div>
                    </div>
                </div>
            </div>

        </div> /* reedy-container */
    );
};

export default Reedy;