import React, { useRef, useState } from "react";
import { Link } from 'react-router-dom';
import '../CSS/nhfk.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/nhfk/nhfk_intro_logo.png';
import Nhfk1 from '../assets/nhfk/cover.jpg';
import Nhfk2 from '../assets/nhfk/nhfk_1.jpg';
import Nhfk3 from '../assets/nhfk/nhfk_2.jpg';
import Nhfk4 from '../assets/nhfk/nhfk_3.jpg';
import Nhfk5 from '../assets/nhfk/nhfk_4.jpg';
import Nhfk6 from '../assets/nhfk/nhfk_5.jpg';
import Nhfk7 from '../assets/nhfk/nhfk_6.jpg';
import Nhfk8 from '../assets/nhfk/nhfk_7.jpg';


const Nhfk = () => {
    return (
        <div className="container nhfk-container project-container">
            <div className="header-container">
                <img src={Logo} alt="New hope for kids" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>New Hope for Kids</h2>
                        <h3>Non-Profit, Print</h3>
                    </div>
                    <div className="half-div">
                        <p> New Hope for Kids is a non profit organization that supports children and families grieving the death of a loved one and to grant wishes to children with life-threatening illnesses in Central Florida. I had the opportunity to create an annual report for them. </p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <h3>Design, Layout</h3>
                    <h2>Annual Report</h2> 
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Nhfk1} alt="New Hope for Kids Annual Report" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Nhfk2} alt="New Hope for Kids Annual Report" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Nhfk3} alt="New Hope for Kids Annual Report" />
                                </div>
                            </div>      
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Nhfk4} alt="New Hope for Kids Annual Report" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Nhfk5} alt="New Hope for Kids Annual Report" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Nhfk6} alt="New Hope for Kids Annual Report" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                             
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Nhfk7} alt="New Hope for Kids Annual Report" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

        </div> /* nhfk-container */
    );
};

export default Nhfk;