import React, { useRef, useState } from "react";
import '../CSS/ucf.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/ucf/ucf_intro_logo.png';
import Poster from '../assets/ucf/poster.jpg';
import Shirt from '../assets/ucf/shirt.jpg';

const Ucf = () => {
    return (
        <div className="container ucf-container project-container">
            <div className="header-container">
                <img src={Logo} alt="ucf Logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>UCF Diversity Poster</h2>
                        <h3>Print</h3>
                    </div>
                    <div className="half-div">
                        <p> UCF asked to create a poster for their Diversity Week event. My poster design was then used to be the graphic for the T-Shirts. </p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Diversity Poster</h2>

                    <div className="flex-container ">
                        <div className="full-div"> 
                            <img src={Poster} alt="Poster" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>T-Shirt</h2>

                    <div className="flex-container ">
                        <div className="full-div"> 
                            <img src={Shirt} alt="Shirt" />
                        </div>
                    </div>
                </div>
            </div>

        </div> /* ucf-container */
    );
};

export default Ucf;