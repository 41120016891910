import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import Logo from '../assets/logo-1.png';

const Nav = () => {

    return (
        <div className="nav-container">
            <div className="nav centered-container">
                <Link to={"/"}>
                    <img src={Logo} alt="Logo"/>
                </Link>
                <div className="nav-items">
                    <Link to={"/#about"}> about </Link>
                    <Link to={"/#work"}> work </Link>
                    <Link to={"/#contact"}> contact </Link>
                </div>
            </div>
        </div>
    );
};

export default Nav;