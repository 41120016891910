import React, { useRef, useState } from "react";
import { Link } from 'react-router-dom';
import '../CSS/suff.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/suff/suff-intro-logo.png';
import Colors from '../assets/suff/suff-colors.png';
import Futura from '../assets/suff/font-futura.png';
import Montserrat from '../assets/suff/font-montserrat.png';
import Website1 from '../assets/suff/suff-1.jpg';
import Website2 from '../assets/suff/suff-2.jpg';
import Website3 from '../assets/suff/suff-3.jpg';
import Website4 from '../assets/suff/suff-4.jpg';
import Website5 from '../assets/suff/suff-5.jpg';
import FactSheet1 from '../assets/suff/suff-6.jpg';
import FactSheet2 from '../assets/suff/suff-7.jpg';

const Suff = () => {
    return (
        <div className="container suff-container project-container">
            <div className="header-container">
                <img src={Logo} alt="Stand Up for forests logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>Stand Up for Forests</h2>
                        <h3>Logo, Non-Profit, Print, UI/UX, Web Design, Web Development</h3>
                        <a href="https://standupforforests.org/" target="_blank" rel="external nofollow"><button>View Website</button></a>
                    </div>
                    <div className="half-div">
                        <p> The Stand Up for Forests campaign was designed to galvanize people and organizations around the message that protecting forests from development and managing them well are the most important things we can do to mitigate the impacts of climate change in the Northeast. For this project, I worked on the logo, toolkits, website design and creation.</p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <h3>Brand Identity</h3>
                    <h2>Typeface & Colors</h2>
                    <div className="flex-container brand-identity">
                        <div className="half-div color-container">
                            <h3>Color Scheme</h3>
                            <img src={Colors} alt="Stand Up for forests Colors" />
                        </div>
                        <div className="half-div">
                            <h3>Fonts</h3>
                            <div className="flex-container font-container">
                                <div className="half-div">
                                    <img src={Futura} alt="Stand Up for forests Futura Font" />
                                    <p>Futura</p>
                                </div>
                                <div className="half-div">
                                    <img src={Montserrat} alt="Stand Up for forests Montserrat Font" />
                                    <p>Montserrat</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <div className="flex-container logo-container">
                        <div className="half-div">
                            <h3>Brand Identity</h3>
                            <h2>Logo</h2>
                            <img src={Logo} alt="Stand Up for forests Logo" />
                        </div>
                        <div className="half-div">
                            <p>When designing the logo, I wanted to make sure that it was symbolic of what the non-profit organization stood for while being tasteful. It was important that the logo maintained it's legiblility in various sizes. The Logomark overall is representative of a tree. Looking deeper into the logo, you can find that the lighter green showcases a person standing which implies that they are in fact standing up for forests. </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel4">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Fact Sheet</h2> 
                    <div className="flex-container">
                        <div className="half-div">  
                            <p>This fact sheet is used as a communication tool to help organizations amplify messages about the connection between forests and health.  </p>
                        </div>
                    </div>

                    <div className="flex-container">
                        <div className="half-div">  
                            <img src={FactSheet1} alt="Stand Up for forests Fact Sheet" />
                        </div>
                        <div className="half-div">  
                            <img src={FactSheet2} alt="Stand Up for forests Fact Sheet" />
                        </div>
                    </div>

                </div>
            </div>

            <div className="panel5">
                <div className="centered-container">
                    <h3>Web Design & Development</h3>
                    <h2>Website</h2> 

                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Website1} alt="Stand Up for forests Website" />
                                </div>
                                <div className="half-div">  
                                    <img src={Website2} alt="Stand Up for forests Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                      
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Website3} alt="Stand Up for forests Website" />
                                </div>
                                <div className="half-div">  
                                    <img src={Website4} alt="Stand Up for forests Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                      
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Website5} alt="Stand Up for forests Website" />
                                </div>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </div>

        </div> /* suff-container */
    );
};

export default Suff;