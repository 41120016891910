import React, { useRef, useState } from "react";
import '../CSS/gmtm.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/gmtm/gmtm_intro_logo.png';
import Gmtm1 from '../assets/gmtm/gmtm1.jpg';
import Gmtm2 from '../assets/gmtm/gmtm2.jpg';
import Gmtm3 from '../assets/gmtm/banner.jpg';
import Gmtm4 from '../assets/gmtm/business-card1.jpg';
import Gmtm5 from '../assets/gmtm/business-card2.jpg';
import Gmtm6 from '../assets/gmtm/email.png';
import App1 from '../assets/gmtm/app1.jpg';
import App2 from '../assets/gmtm/app2.jpg';
import App3 from '../assets/gmtm/app3.jpg';
import App4 from '../assets/gmtm/app4.jpg';
import App5 from '../assets/gmtm/app5.jpg';
import App6 from '../assets/gmtm/app6.jpg';
import App7 from '../assets/gmtm/app7.jpg';

const Gmtm = () => {
    return (
        <div className="container gmtm-container project-container">
            <div className="header-container">
                <img src={Logo} alt="GMTM Logo" />
            </div>
            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>GMTM</h2>
                        <h3>Email Design & Development, Print, Web Design</h3>
                    </div>
                    <div className="half-div">
                        <p> GMTM is a social network for athletes, coaches, trainers and industry staff. They enable athletes from all over the world to connect directly with the industry's top organizations and professionals. </p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Business Cards</h2>
                    <div className="flex-container">
                        <div className="half-div">
                            <img src={Gmtm4} alt="Business Cards" />
                        </div>
                        <div className="half-div">
                            <img src={Gmtm5} alt="Business Cards" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <h3>UI/UX, Web Design</h3>
                    <h2>Website Pages</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Gmtm2} alt="Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Gmtm1} alt="Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel4">
                <div className="centered-container">
                    <h3>UI/UX</h3>
                    <h2>App</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={App1} alt="App" />
                                </div>
                                <div className="half-div">  
                                    <img src={App2} alt="App" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={App3} alt="App" />
                                </div>
                                <div className="half-div">  
                                    <img src={App4} alt="App" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={App5} alt="App" />
                                </div>
                                <div className="half-div">  
                                    <img src={App6} alt="App" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={App7} alt="App" />
                                </div>
                                <div className="half-div">  
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel5">
                <div className="centered-container">
                    <div className="flex-container ">
                        <div className="half-div"> 
                            <h3>Print</h3>
                            <h2>Pop Up Banner</h2>
                        </div>
                        <div className="full-div"> 
                            <img src={Gmtm3} alt="Banner" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel6">
                <div className="centered-container">
                    <div className="flex-container">
                        <div className="half-div">
                            <h3>Web Design</h3>
                            <h2>Email</h2>
                        </div>
                        <div className="full-div">
                            <img src={Gmtm6} alt="Gmtm Email" />
                        </div>
                    </div>
                </div>
            </div>

        </div> /* Gmtm-container */
    );
};

export default Gmtm;