import React, { useRef, useState } from "react";
import '../CSS/ito.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/ito/ito_intro_logo.png';
import Ito1 from '../assets/ito/ito_1.jpg';

const Ito = () => {
    return (
        <div className="container ito-container project-container">
            <div className="header-container">
                <img src={Logo} alt="Ito Logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>ITO</h2>
                        <h3>Logo, Print, UI/UX, Web Design</h3>
                    </div>
                    <div className="half-div">
                        <p> ITO is a communications assistant that helps users make voice and video connections from popular text messaging platforms. ITO is a product of TROPO, inc. </p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <div className="flex-container">
                        <div className="half-div">
                            <h3>Brand Identity</h3>
                            <h2>Logo</h2>
                            <img src={Logo} alt="Ito Logo"className="new-logo" />
                        </div>
                        <div className="half-div">
                            <p>The logo for ITO was required to indicate in some way the idea of communication. To do this, I crafted the "O" to show this through negative space. The difference in color in the logo was to highlight the word "TO" to emphasize person receiving the communication.  </p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <h3>UI/UX, Web Design, Web Development</h3>
                    <h2>One Page Website</h2>

                    <div className="flex-container ">
                        <div className="full-div"> 
                            <img src={Ito1} alt="Website" />
                        </div>
                    </div>
                </div>
            </div>

        </div> /* ito-container */
    );
};

export default Ito;