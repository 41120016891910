import React from "react";
import '../CSS/disti.scss';

import ReactPlayer from 'react-player/lazy';
import VideoCes from '../assets/disti/video-ces.mp4';
import VideoCesImage from '../assets/disti/video-ces.jpg';
import VideoSpace from '../assets/disti/video-space.mp4';

import Logo from '../assets/disti/disti-intro-logo.png';
import BannerGLS from '../assets/disti/banner-gls.jpg';
import BannerMC from '../assets/disti/banner-mixed-criticality.jpg';
import LogoColor from '../assets/disti/disti-logo-color.png';
import DistiSketches from '../assets/disti/disti-sketches.jpg';
import DistiPrototypes from '../assets/disti/disti-prototypes.jpg';
import Spacetech from '../assets/disti/disti-spacetech.jpg';
import Fmt1 from '../assets/disti/one-sheet-fmt-1.jpg';
import Fmt2 from '../assets/disti/one-sheet-fmt-2.jpg';
import VeMentor from '../assets/disti/one-sheet-ve-mentor.jpg';
import PosterHP from '../assets/disti/poster-hp.jpg';
import PosterStryker from '../assets/disti/poster-stryker.jpg';
import PosterVertex from '../assets/disti/poster-vertex.jpg';
import CesBooth from '../assets/disti/ces-booth.jpg';
import Ibooth1 from '../assets/disti/ibooth.jpg';
import Ibooth2 from '../assets/disti/ibooth2.jpg';
import Ibooth3 from '../assets/disti/ibooth3.jpg';
import Ad1 from '../assets/disti/ad-1.jpg';
import Ad2 from '../assets/disti/ad-2.jpg';
import Ad3 from '../assets/disti/ad-3.jpg';
import Ad4 from '../assets/disti/ad-4.jpg';
import Mousepad from '../assets/disti/mousepad.jpg';
import Carpet from '../assets/disti/disti-carpet.jpg';

const Disti = () => {
    return (
        <div className="container disti-container project-container">
            <div className="header-container">
                <img src={Logo} alt="DiSTI logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>DiSTI</h2>
                        <h3>Logo, Print, product Design, Tradeshow, VR, Web Design, Social Graphics, Video</h3>
                    </div>
                    <div className="half-div">
                        <p> The DiSTI Corporation is a global leader in virtual maintenance training and HMI development tools. Their clientele come from a wide ranges of industries with their products used on Earth and beyond.</p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <div className="flex-container logo-container">
                        <div className="half-div">
                            <h3>Brand Identity</h3>
                            <h2>Logo</h2>
                            <img src={LogoColor} alt="DiSTI Logo" />
                        </div>
                        <div className="half-div">
                            <p>DiSTI was looking to add a logomark to accompany the logo they already had in place. The original logo consisted of the word "DiSTI" and they felt that it wasn't versatile when it came to certain use cases such as social media profile images. After many sketches, and iterations, we have landed on the design that showcases their company mission the best while complimenting the wordmark. The icon's negative space represents the companies interactive capabilities through HMI, VR, and AR, as well as innovation to push ahead. </p>
                        </div>
                    </div>
                    <h3>Sketches & Revisions</h3>
                    <div className="flex-container logo-prototypes">
                        <div className="half-div">  
                            <img src={DistiSketches} alt="DiSTI Logo Sketches" />
                        </div>
                        <div className="half-div">  
                            <img src={DistiPrototypes} alt="DiSTI Logo Prototypes" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <h3>Print Graphics</h3>
                    <h2>Tradeshow Banners & Booths</h2> 

                    <div className="flex-container tradeshow-container">
                        <div>
                            <img src={Spacetech} alt="SpaceTech Banner" />
                            <p>8'x10'</p>
                        </div> 
                        <div>
                            <img src={BannerMC} alt="Mixed Criticality Banner" />
                            <p>Pop Up Banner</p>
                        </div> 
                        <div>
                            <img src={BannerGLS} alt="GL Studio Banner" />
                            <p>Pop Up Banner</p>
                        </div> 
                    </div>
                    <div className="flex-container">
                        <div className="third-div">  
                            <img src={Ibooth1} alt="DiSTI booth" />
                        </div>
                        <div className="third-div">  
                            <img src={Ibooth2} alt="DiSTI booth" />
                        </div>
                        <div className="third-div">  
                            <img src={Ibooth3} alt="DiSTI booth" />
                        </div>
                    </div>
                    <div className="flex-container">
                        <div className="half-div">  
                            <img src={CesBooth} alt="DiSTI booth" />
                        </div>
                        <div className="half-div">  
                        </div>
                    </div>

                </div>
            </div>

            <div className="panel4">
                <div className="centered-container">
                    <h3>Print Graphics</h3>
                    <h2>One Sheets</h2> 

                    <div className="flex-container one-sheets">
                        <div className="half-div">  
                            <img src={Fmt1} alt="DiSTI One Sheet FMT Front" />
                            <p>Front</p>
                        </div>
                        <div className="half-div">  
                            <img src={Fmt2} alt="DiSTI One Sheet FMT Back" />
                            <p>Back</p>
                        </div>
                    </div>

                    <div className="flex-container one-sheets">
                        <div className="half-div">  
                            <img src={VeMentor} alt="DiSTI One Sheet VE Mentor" />
                        </div>
                        <div className="half-div">  
                        </div>
                    </div>

                </div>
            </div>

            <div className="panel5">
                <div className="centered-container">
                    <h3>Print Graphics</h3>
                    <h2>Client Posters</h2> 

                    <div className="flex-container one-sheets">
                        <div className="third-div">  
                            <img src={PosterHP} alt="DiSTI Poster HP" />
                        </div>
                        <div className="third-div">  
                            <img src={PosterVertex} alt="DiSTI Poster Vertex" />
                        </div>
                        <div className="third-div">  
                            <img src={PosterStryker} alt="DiSTI Poster Stryker" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel6">
                <div className="centered-container">
                    <h3>Marketing</h3>
                    <h2>Videography</h2> 

                    <div className="flex-container disti-videos">
                        <div className="half-div">  
                            <ReactPlayer url={VideoCes} controls="true" width="95%"/>
                        </div>
                        <div className="half-div">  
                            <ReactPlayer url={VideoSpace} controls="true" width="95%"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel7">
                <div className="centered-container">
                    <h3>Print Graphics</h3>
                    <h2>Tradeshow Ads</h2> 

                    <div className="flex-container">
                        <div className="half-div">  
                            <img src={Ad1} alt="DiSTI Ad" />
                        </div>
                        <div className="half-div">  
                            <img src={Ad2} alt="DiSTI Ad" />
                        </div>
                    </div>
                    <div className="flex-container">
                        <div className="half-div">  
                            <img src={Ad3} alt="DiSTI Ad" />
                        </div>
                        <div className="half-div">  
                            <img src={Ad4} alt="DiSTI Ad" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel8">
                <div className="centered-container">
                    <h3>Print Graphics</h3>
                    <h2>Misc</h2> 

                    <div className="flex-container">
                        <div className="half-div">  
                            <img src={Mousepad} alt="DiSTI Mousepad" />
                            <p>Mousepad</p>
                        </div>
                        <div className="half-div">  
                            <img src={Carpet} alt="DiSTI Carpet" />
                            <p>Rug</p>
                        </div>
                    </div>
                </div>
            </div>
           

        </div> /* Disti-container */
    );
};

export default Disti;