import React from "react";
import '../CSS/teachboost.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/teachboost/teachboost-intro-logo.png';
import Teachboost1 from '../assets/teachboost/teachboost-1.jpg';
import Teachboost2 from '../assets/teachboost/teachboost-2.jpg';
import Teachboost3 from '../assets/teachboost/teachboost-3.jpg';
import Spotlight1 from '../assets/teachboost/spotlight-1.jpg';
import Spotlight2 from '../assets/teachboost/spotlight-2.jpg';
import Carousel1 from '../assets/teachboost/carousel-1.png';
import Carousel2 from '../assets/teachboost/carousel-2.png';
import Carousel3 from '../assets/teachboost/carousel-3.png';
import Carousel4 from '../assets/teachboost/carousel-4.png';


const Teachboost = () => {
    return (
        <div className="container teachboost-container project-container">
            <div className="header-container">
                <img src={Logo} alt="teachboost logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>Teachboost</h2>
                        <h3>Print, Web Design</h3>
                    </div>
                    <div className="half-div">
                        <p> TeachBoost powers every step of the K-12 teacher development process, from evaluation and compliance to personalized coaching that drives educator growth. They work with some of the best schools and districts around the world—from their roots in New York City to over 800 organizations in 44 states and 5 countries.</p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h3>Client guidelines</h3>
                        <h2>Design</h2>
                    </div>
                    <div className="half-div">
                        <p> As I create assets for Teachbost, I have to ensure to maintain their company brand guidelines. Assets such as their logo usage, brand colors, typefaces, illustrations, and more are important to keep their brand strong and maintain consistency with all of their marketing.</p>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <div className="flex-container ">
                        <div className="half-div">
                            <h3>Print</h3>
                            <h2>One-Pagers</h2>
                        </div>
                        <div className="half-div">
                            <p>The goal of these one-pagers are to We want district leaders to read this piece and realize the vital role that data plays in helping them to understand their district's coaching program and the impact it is/isn't having on teaching, learning, and student outcomes.</p>
                        </div>
                    </div>

                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Teachboost1} alt="Teachboost one-pager" />
                                </div>
                                <div className="half-div">  
                                    <img src={Teachboost2} alt="Teachboost one-pager" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                      
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Teachboost3} alt="Teachboost one-pager" />
                                </div>
                                <div className="half-div">  
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel4">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Spotlight Case Study</h2>
                    <div className="flex-container">
                        <div className="half-div">  
                            <img src={Spotlight1} alt="Spotlight" />
                            <p>Front</p>
                        </div>
                        <div className="half-div">  
                            <img src={Spotlight2} alt="Spotlight" />
                            <p>Back</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel5">
                <div className="centered-container">
                    <div className="flex-container ">
                        <div className="half-div">
                            <h3>Web Design</h3>
                            <h2>Carousel</h2>
                        </div>
                        <div className="half-div">
                            <p>This specific piece is to be used on their website to market data that they have gathered on the market and Teachboost coaching programs.</p>
                        </div>
                    </div>

                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Carousel1} alt="Teachboost carousel" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Carousel2} alt="Teachboost carousel" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Carousel3} alt="Teachboost carousel" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Carousel4} alt="Teachboost carousel" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

        
        </div> /* Teachboost-container */
    );
};

export default Teachboost;