import React from "react";
import '../CSS/fcb.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/fcb/fcb_intro_logo.png';
import Logo2 from '../assets/fcb/fcb_intro_logo2.png';
import Fcb1 from '../assets/fcb/fcb_1.jpg';
import Fcb2 from '../assets/fcb/fcb_2.jpg';
import Fcb3 from '../assets/fcb/fcb_3.jpg';
import Fcb4 from '../assets/fcb/fcb_4.jpg';
import Book1 from '../assets/fcb/book1.jpg';
import Book2 from '../assets/fcb/book2.jpg';
import Book3 from '../assets/fcb/book3.jpg';
import Book4 from '../assets/fcb/book4.jpg';
import App1 from '../assets/fcb/app1.jpg';
import App2 from '../assets/fcb/app2.jpg';
import App3 from '../assets/fcb/app3.jpg';
import App4 from '../assets/fcb/app4.jpg';
import App5 from '../assets/fcb/app5.jpg';
import App6 from '../assets/fcb/app6.jpg';
import App7 from '../assets/fcb/app7.jpg';
import App8 from '../assets/fcb/app8.jpg';
import App9 from '../assets/fcb/app9.jpg';
import App10 from '../assets/fcb/app10.jpg';

const Fcb = () => {
    return (
        <div className="container fcb-container project-container">
            <div className="header-container">
                <img src={Logo} alt="Barcelona logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container">
                    <div className="flex-container">
                        <div className="half-div">
                            <h2>FC Barcelona</h2>
                            <h3>Logo, Print, Product Design, UI/UX</h3>
                        </div>
                        <div className="half-div">
                            <p>FC Barcelona is a soccer team that was founded in 1899 and is currently from Barcelona, Spain. Being my favorite soccer team, I wanted to simplify their logo and see what my take on their brand would be like. I wanted to take them into a new direction. Instead of using the stripes from the logo for their kits, I wanted to go more abstract and take elements from the city. Gaudi is a famous architect that has a lot of his work throughout Barcelona. His work is typically organic, therefore, I wanted to implement organic shapes into the soccer kit. I then carried it through the other elements in the brand. </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <div className="flex-container logo-container">
                        <div className="half-div">
                            <h3>Brand Identity</h3>
                            <h2>Logo</h2>
                            <img src={Logo2} alt="Barcelona Logo" />
                        </div>
                        <div className="half-div">
                            <p>For the logo to handle smaller sizes, I decided to tweak the lower half of the current logo. I reduced the number of stripes, changed the soccer ball and increased the ball size. </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <div className="flex-container logo-container">
                        <div className="half-div">
                            <h3>Product Design</h3>
                            <h2>Futbol Kit</h2>
                        </div>
                        <div className="half-div">
                            <img src={Fcb1} alt="Soccer Kit" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel4">
                <div className="centered-container">
                    <div className="flex-container logo-container">
                        <div className="half-div">
                            <h3>Product Design</h3>
                            <h2>Scarves</h2>
                        </div>
                        <div className="half-div">
                            <img src={Fcb2} alt="Scarves" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel5">
                <div className="centered-container">
                    <div className="flex-container logo-container">
                        <div className="half-div">
                            <h3>Product Design</h3>
                            <h2>Supporters Kit</h2>
                        </div>
                        <div className="half-div">
                            <img src={Fcb3} alt="Supporters Kit" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel5">
                <div className="centered-container">
                    <div className="flex-container logo-container">
                        <div className="half-div">
                            <h3>Print</h3>
                            <h2>Passbook</h2>
                        </div>
                        <div className="half-div">
                            <img src={Fcb4} alt="Passbook" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel6">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Season Booklet</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Book1} alt="Season Booklet" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Book2} alt="Season Booklet" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Book3} alt="Season Booklet" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Book4} alt="Season Booklet" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel7">
                <div className="centered-container">
                    <h3>UI/UX</h3>
                    <h2>App - Smart FCB Soccer ball</h2>
    
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={App1} alt="App Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={App2} alt="App Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={App3} alt="App Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={App4} alt="App Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={App5} alt="App Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={App6} alt="App Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={App7} alt="App Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={App8} alt="App Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={App9} alt="App Screens" />
                                </div>
                                <div className="half-div">  
                                    <img src={App10} alt="App Screens" />
                                </div>
                            </div>
                        </SwiperSlide>
            
                    </Swiper>
                </div>
            </div>

        </div> /* fcb-container */
    );
};

export default Fcb;