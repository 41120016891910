import React from "react";
import { Link } from 'react-router-dom';
import '../CSS/home.scss';

import Atls from '../assets/atls/atls_intro_logo2.png';
import Bft from '../assets/bft/b4t_intro_logo.png';
import Buna from '../assets/buna/buna_intro_logo.png';
import Disti from '../assets/disti/disti-intro-logo.png';
import Fcb from '../assets/fcb/fcb_intro_logo.png';
import Gmtm from '../assets/gmtm/gmtm_intro_logo.png';
import Illo from '../assets/illo/illustrations_intro_logo.png';
import Ito from '../assets/ito/ito_intro_logo.png';
import Leslie from '../assets/leslie-hielema/leslie_intro_logo.png';
import LizTeam from '../assets/liz-team/lizteam_intro_logo.png';
import MemoryMates from '../assets/memory-mates/memory_mates_intro_logo.png';
import Metalocator from '../assets/metalocator/metalocator-intro-logo.png';
import Nhfk from '../assets/nhfk/nhfk_intro_logo.png';
import Photoday from '../assets/photoday/photoday_intro_logo.png';
import Reedy from '../assets/reedy/reedy_intro_logo.png';
import Suff from '../assets/suff/suff-intro-logo.png';
import SushiKatana from '../assets/sushi-katana/sk_intro_logo.png';
import Teachboost from '../assets/teachboost/teachboost-intro-logo.png';
import Tkci from '../assets/tkc/tkc_intro_logo.png';
import Ucf from '../assets/ucf/ucf_intro_logo.png';
import Wanderian from '../assets/wanderian/wanderian_intro_logo.png';

import Addy from '../assets/addy.png';

const Home = () => {
    return (
        <div className="container home-container">
            <div className="panel1" id="about">
                <div className="centered-container">
                    <div className="text-container">
                        <h1> Hello there! I'm Joy, an Orlando based designer & front-end developer.</h1>
                        <span className="colored-line"></span>
                        <p>I have a background in UI/UX, website development, web design, and print design. With this well rounded knowledge, I am comfortable in executing both print and web-based projects from concept through finished product. As I create, I aim to craft meaningful products while keeping a human centered approach with empathy at the forefront. </p>
                        <a href="mailto:design@joygarcia.com"><button>Let's connect!</button></a>
                    </div>
                </div>
                <div className="panel1-bg"></div>
            </div> {/* panel 1 */}

            <div className="panel2" id="work">
                <div className="centered-container">
                    <h2>My Work</h2>
                    <span className="colored-line"></span>
                    <p>Check out my wide range of experience working with non-profits, startups, tech companies, government contracts, and more. </p>
                    <img src={Addy} alt="Addy" className="addy"/>
                    <div className="projects">
                        <div className="project-item disti">
                            <Link to={"/disti"} className="project-img">
                                <img src={Disti} alt="DiSTI" />
                                <p className="project-categories"> Logo, Print, product Design, Tradeshow, VR, Web Design, Social Graphics, Video</p>
                            </Link>
                        </div>

                        <div className="project-item metalocator">
                            <Link to={"/metalocator"} className="project-img">
                                <img src={Metalocator} alt="Metalocator" />
                                <p className="project-categories"> SEO Pages, UI/UX, Web Design</p>
                            </Link>
                           
                        </div>

                        <div className="project-item teachboost">
                            <Link to={"/teachboost"} className="project-img">
                                <img src={Teachboost} alt="Teachboost" />
                                <p className="project-categories"> Print, Web Design</p>
                            </Link>
                        </div>

                        <div className="project-item suff">
                            <Link to={"/stand-up-for-forests"} className="project-img">
                                <img src={Suff} alt="Stand Up for Forests" />
                                <p className="project-categories"> Logo, Non-Profit, Print, UI/UX, Web Design, Web Development</p>
                            </Link>
                        </div>

                        <div className="project-item photoday">
                            <Link to={"/photoDay"} className="project-img">
                                <img src={Photoday} alt="PhotoDay" />
                                <p className="project-categories"> Logo, Print, Product Design, Tradeshow, UI/UX, Web Design, Web Development</p>
                            </Link>
                        </div>

                        <div className="project-item tkc">
                            <Link to={"/tkci"} className="project-img">
                                <img src={Tkci} alt="The Kingdom Church" />
                                <p className="project-categories"> UI/UX, Web Design, Web Development</p>
                            </Link>
                        </div>

                        <div className="project-item atls">
                            <Link to={"/atls"} className="project-img">
                                <img src={Atls} alt="ATLS" />
                                <p className="project-categories"> Print, Textbook</p>
                            </Link>
                        </div>

                        <div className="project-item nhfk">
                            <Link to={"/new-hope-for-kids"} className="project-img">
                                <img src={Nhfk} alt="New Hope for Kids" />
                                <p className="project-categories"> Non-Profit, Print</p>
                            </Link>
                        </div>

                        <div className="project-item reedy">
                            <Link to={"/reedy-photo"} className="project-img">
                                <img src={Reedy} alt="Reedy" />
                                <p className="project-categories"> Logo, Print, UI/UX, Web Design</p>
                            </Link>
                        </div>

                        <div className="project-item memory-mates">
                            <Link to={"/memory-mates"} className="project-img">
                                <img src={MemoryMates} alt="Memory Mates" />
                                <p className="project-categories"> Print</p>
                            </Link>
                        </div>

                        <div className="project-item b4t">
                            <Link to={"/battle-for-treasure"} className="project-img">
                                <img src={Bft} alt="Battle for Treasure" />
                                <p className="project-categories"> Print</p>
                            </Link>
                        </div>

                        <div className="project-item gmtm">
                            <Link to={"/gmtm"} className="project-img">
                                <img src={Gmtm} alt="GMTM" />
                                <p className="project-categories"> Email Design & Development, Print, Web Design</p>
                            </Link>
                        </div>

                        <div className="project-item sushi-katana">
                            <Link to={"/sushi-katana"} className="project-img">
                                <img src={SushiKatana} alt="Sushi Katana" />
                                <p className="project-categories"> Logo, Print</p>
                            </Link>
                        </div>

                        <div className="project-item ito">
                            <Link to={"/ito"} className="project-img">
                                <img src={Ito} alt="Ito" />
                                <p className="project-categories"> Logo, UI/UX, Web Design, Web Development</p>
                            </Link>
                        </div>

                        <div className="project-item wanderian">
                            <Link to={"/wanderian"} className="project-img">
                                <img src={Wanderian} alt="Wanderian" />
                                <p className="project-categories"> Logo, UI/UX, Web Design</p>
                            </Link>
                        </div>

                        <div className="project-item fcb">
                            <Link to={"/fcb"} className="project-img">
                                <img src={Fcb} alt="FC Barcelona" />
                                <p className="project-categories"> Logo, Print, Product Design, UI/UX</p>
                            </Link>   
                        </div>

                        <div className="project-item ucf">
                            <Link to={"/ucf"} className="project-img">
                                <img src={Ucf} alt="UCF" />
                                <p className="project-categories"> Print</p>
                            </Link>
                        </div>

                        <div className="project-item liz-team">
                            <Link to={"/the-liz-team"} className="project-img">
                                <img src={LizTeam} alt="The Liz Team" />
                                <p className="project-categories"> Print, Product Design</p>
                            </Link>
                        </div>

                        <div className="project-item leslie">
                            <Link to={"/leslie"} className="project-img">
                                <img src={Leslie} alt="Leslie Hielema" />
                                <p className="project-categories"> UI/UX, Web Design, Web Development</p>
                            </Link>
                        </div>

                        <div className="project-item buna">
                            <Link to={"/buna-tetu"} className="project-img">
                                <img src={Buna} alt="Buna Tetu" />
                                <p className="project-categories"> Logo, UI/UX, Web Design</p>
                            </Link>
                        </div>

                        <div className="project-item illustrations">
                            <Link to={"/illustrations"} className="project-img">
                                <img src={Illo} alt="Illustrations" />
                                <p className="project-categories"> Illustrations</p>
                            </Link>
                        </div>
                        
                    </div>
                </div>
            </div> {/* panel 2 */}

            <div className="panel3" id="contact">
                <div className="centered-container">
                    <h2> Wanna Chat?</h2>
                    <span className="colored-line"></span>
                    <p>Looking forward to connecting!</p>
                    <a href="mailto:design@joygarcia.com"><button>Send an Email</button></a>
                </div>
            </div>

        </div> /* home-container */
    );
};

export default Home;