import React, { useRef, useState } from "react";
import { Link } from 'react-router-dom';
import '../CSS/tkci.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/tkc/tkc_intro_logo.png';
import Tkc1 from '../assets/tkc/tkc1.jpg';
import Tkc2 from '../assets/tkc/tkc2.jpg';
import Tkc3 from '../assets/tkc/tkc3.jpg';
import Tkc4 from '../assets/tkc/tkc4.jpg';
import Tkc5 from '../assets/tkc/tkc5.jpg';


const Tkci = () => {
    return (
        <div className="container tkci-container project-container">
            <div className="header-container">
                <img src={Logo} alt="The Kingdom Church logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>The Kingdom Church</h2>
                        <h3>UI/UX, Web Design, Web Development</h3>
                        <a href="https://www.tkci.org" target="_blank" rel="external nofollow noreferrer"><button>View Website</button></a>
                    </div>
                    <div className="half-div">
                        <p> The Kingdom Church is a vibrant & energetic congregation located in Central Florida. The goal of this project was to redesign their website to have a more modern design aesthetic and reorganize their many site pages for more clarity to the end user. </p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <h3>UI/UX, Web Design, Web Development</h3>
                    <h2>Website</h2> 
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Tkc2} alt="The Kingdom Church Website" />
                                </div>
                                <div className="half-div">  
                                    <img src={Tkc3} alt="The Kingdom Church Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>                      
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Tkc4} alt="The Kingdom Church Website" />
                                </div>
                                <div className="half-div">  
                                    <img src={Tkc5} alt="The Kingdom Church Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>



                </div>
            </div>


        </div> /* tkci-container */
    );
};

export default Tkci;