import React from "react";
import '../CSS/photoday.scss';

// Import Swiper 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Logo from '../assets/photoday/photoday_intro_logo.png';
import LogoColor from '../assets/photoday/photoday-logo-color.png';
import Colors from '../assets/photoday/pd-colors.png';
import Omnes from '../assets/photoday/pd-omnes.png';
import OpenSans from '../assets/photoday/pd-open.png';
import Lanyard1 from '../assets/photoday/photoday2.jpg';
import Lanyard2 from '../assets/photoday/photoday3.jpg';
import Lanyard3 from '../assets/photoday/photoday4.jpg';
import Shirt1 from '../assets/photoday/photoday5.jpg';
import Shirt2 from '../assets/photoday/photoday6.jpg';
import Shirt3 from '../assets/photoday/photoday7.jpg';
import Shirt4 from '../assets/photoday/photoday8.jpg';
import Shirt5 from '../assets/photoday/photoday9.jpg';
import Shirt6 from '../assets/photoday/photoday10.jpg';
import Booth1 from '../assets/photoday/photoday11.jpg';
import Booth2 from '../assets/photoday/photoday12.jpg';
import Booth3 from '../assets/photoday/photoday13.jpg';
import Booth4 from '../assets/photoday/photoday14.jpg';
import Pin from '../assets/photoday/photoday15.jpg';
import Brochure1 from '../assets/photoday/photoday16.jpg';
import Brochure2 from '../assets/photoday/photoday17.jpg';
import Brochure3 from '../assets/photoday/photoday18.jpg';
import Brochure4 from '../assets/photoday/photoday19.jpg';
import Brochure5 from '../assets/photoday/photoday20.jpg';
import Bookmark from '../assets/photoday/photoday22.jpg';
import Packaging1 from '../assets/photoday/photoday23.jpg';
import Packaging2 from '../assets/photoday/photoday24.jpg';
import Packaging3 from '../assets/photoday/photoday25.jpg';
import Blog1 from '../assets/photoday/photoday26.jpg';
import Blog2 from '../assets/photoday/photoday27.jpg';
import Blog3 from '../assets/photoday/photoday28.jpg';
import Blog4 from '../assets/photoday/photoday29.jpg';
import Blog5 from '../assets/photoday/photoday30.jpg';
import Blog6 from '../assets/photoday/photoday31.jpg';
import Blog7 from '../assets/photoday/photoday32.jpg';
import Blog8 from '../assets/photoday/photoday33.jpg';
import Blog9 from '../assets/photoday/photoday34.jpg';
import Webinar1 from '../assets/photoday/photoday35.jpg';
import Webinar2 from '../assets/photoday/photoday36.jpg';
import Webinar3 from '../assets/photoday/photoday37.jpg';
import Webinar4 from '../assets/photoday/photoday38.jpg';
import Webinar5 from '../assets/photoday/photoday39.jpg';
import Webinar6 from '../assets/photoday/photoday40.jpg';
import Webinar7 from '../assets/photoday/photoday41.jpg';
import Webinar8 from '../assets/photoday/photoday42.jpg';
import Site1 from '../assets/photoday/photoday43.jpg';
import Site2 from '../assets/photoday/photoday44.jpg';
import Site3 from '../assets/photoday/photoday45.jpg';
import Other1 from '../assets/photoday/photoday46.jpg';
import Other2 from '../assets/photoday/photoday47.jpg';
import Other3 from '../assets/photoday/photoday48.jpg';
import Mug1 from '../assets/photoday/mug1.png';
import Mug2 from '../assets/photoday/mug2.png';
import Mug3 from '../assets/photoday/mug3.png';


const Photoday = () => {
    return (
        <div className="container photoday-container project-container">
            <div className="header-container">
                <img src={Logo} alt="photoday logo" />
            </div>

            
            <div className="panel1">
                <div className="centered-container flex-container">
                    <div className="half-div">
                        <h2>Photoday</h2>
                        <h3>Logo, Print, Product Design, Tradeshow, UI/UX, Web Design, Web Development</h3>
                    </div>
                    <div className="half-div">
                        <p> PhotoDay is a platform created to make school, sports, and event volume photography convenient for parents and simple for photographers. It provides an online storefront for photography studios to sell to their customers.</p>
                    </div>
                </div>
            </div>

            <div className="panel2">
                <div className="centered-container">
                    <h3>Brand Identity</h3>
                    <h2>Typeface & Colors</h2>
                    <div className="flex-container brand-identity">
                        <div className="half-div color-container">
                            <h3>Color Scheme</h3>
                            <img src={Colors} alt="PhotoDay Colors" />
                        </div>
                        <div className="half-div">
                            <h3>Fonts</h3>
                            <div className="flex-container font-container">
                                <div className="half-div">
                                    <img src={Omnes} alt="PhotoDay Omnes Font" />
                                    <p>Omnes Cryllic Medium</p>
                                </div>
                                <div className="half-div">
                                    <img src={OpenSans} alt="PhotoDay Open Sans Font" />
                                    <p>Open Sans</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel3">
                <div className="centered-container">
                    <div className="flex-container logo-container">
                        <div className="half-div">
                            <h3>Brand Identity</h3>
                            <h2>Logo</h2>
                            <img src={LogoColor} alt="PhotoDay Logo" />
                        </div>
                        <div className="half-div">
                            <p>When designing the logo, I wanted to make sure that it had character, felt friendly but maintained professionalism. This logo displays in front of not only PhotoDay's lab partners and customers but also the photographer's clients.  </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel0">
                <div className="centered-container">
                    <h3>UI/UX, Web Design, Web Development</h3>
                    <h2>Website</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">
                                    <img src={Site1} alt="Website" />
                                </div>
                                <div className="half-div">
                                    <img src={Site2} alt="Website" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">
                                    <img src={Site3} alt="Website" />
                                </div>
                                <div className="half-div">
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel4">
                <div className="centered-container">
                    <h3>Product Design</h3>
                    <h2>Lanyards</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Lanyard1} alt="Lanyard" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Lanyard2} alt="Lanyard" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Lanyard3} alt="Lanyard" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel5">
                <div className="centered-container">
                    <h3>Product Design</h3>
                    <h2>Shirts</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Shirt1} alt="Shirt" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Shirt2} alt="Shirt" />
                                </div>
                                <div className="half-div">  
                                    <img src={Shirt3} alt="Shirt" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Shirt6} alt="Shirt" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Shirt4} alt="Shirt" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Shirt5} alt="Shirt" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel6">
                <div className="centered-container">
                    <h3>Product Design</h3>
                    <h2>Mugs</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Mug1} alt="Mug" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Mug2} alt="Mug" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Mug3} alt="Mug" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel7">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Tradeshow Booth & Banner</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Booth1} alt="Booth" />
                                    <img src={Booth2} alt="Booth" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Booth3} alt="Booth" />
                                </div>
                                <div className="half-div">  
                                    <img src={Booth4} alt="Booth" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel8">
                <div className="centered-container">
                    <h3>Product Design</h3>
                    <h2>Lapel & Bookmarks</h2>
                    <div className="flex-container">
                        <div className="half-div">
                            <img src={Pin} alt="Pin" />
                        </div>
                        <div className="half-div">
                            <img src={Bookmark} alt="Bookmark" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel9">
                <div className="centered-container">
                    <h3>Print</h3>
                    <h2>Tri-fold</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Brochure4} alt="Brochure" />
                                    <img src={Brochure5} alt="Brochure" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Brochure1} alt="Brochure" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Brochure2} alt="Brochure" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="full-div">  
                                    <img src={Brochure3} alt="Brochure" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel10">
                <div className="centered-container">
                    <h3>Print Design</h3>
                    <h2>Packaging</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Packaging1} alt="Packaging" />
                                </div>
                                <div className="half-div">  
                                    <img src={Packaging2} alt="Packaging" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Packaging3} alt="Packaging" />
                                </div>
                                <div className="half-div">  
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className="panel11">
                <div className="centered-container">
                    <h3>Web Design</h3>
                    <h2>Blog Images</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Blog4} alt="Blog" />
                                </div>
                                <div className="half-div">  
                                    <img src={Blog2} alt="Blog" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Blog3} alt="Blog" />
                                </div>
                                <div className="half-div"> 
                                    <img src={Blog6} alt="Blog" /> 
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Blog7} alt="Blog" />
                                </div>
                                <div className="half-div">  
                                    <img src={Blog8} alt="Blog" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Blog5} alt="Blog" />
                                </div>
                                <div className="half-div"> 
                                    <img src={Blog1} alt="Blog" /> 
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Blog9} alt="Blog" />
                                </div>
                                <div className="half-div">  
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel12">
                <div className="centered-container">
                    <h3>Web Design</h3>
                    <h2>Webinar Images</h2>
                    <Swiper navigation={true} modules={[Navigation]} className="web-container">
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Webinar1} alt="Webinar" />
                                </div>
                                <div className="half-div">  
                                    <img src={Webinar2} alt="Webinar" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Webinar3} alt="Webinar" />
                                </div>
                                <div className="half-div"> 
                                    <img src={Webinar4} alt="Webinar" /> 
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Webinar5} alt="Webinar" />
                                </div>
                                <div className="half-div">  
                                    <img src={Webinar6} alt="Webinar" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex-container">
                                <div className="half-div">  
                                    <img src={Webinar7} alt="Webinar" />
                                </div>
                                <div className="half-div"> 
                                    <img src={Webinar8} alt="Webinar" /> 
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="panel13">
                <div className="centered-container">
                    <h3>Web Design</h3>
                    <h2>Misc</h2>
                    <div className="flex-container">
                        <div className="half-div">
                            <img src={Other1} alt="Misc" />
                        </div>
                        <div className="half-div">
                            <img src={Other2} alt="Misc" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="panel14">
                <div className="centered-container">
                    <h3>Web Design</h3>
                    <h2>Infographic</h2>
                    <div className="flex-container">
                        <div className="half-div">
                            <img src={Other3} alt="Infographic" />
                        </div>
                    </div>
                </div>
            </div>


        
        </div> /* Photoday-container */
    );
};

export default Photoday;